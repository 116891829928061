<template>
  <b-row>
    <b-col lg="8" md="12" sm="12" cols="12">
      <b-card-actions
          ref="first-card"
          class="list-card"
          @refresh="refreshStop('first-card')"
      >
        <b-row class="mt-50 mb-5">
          <div class="card-header-row" :style="{backgroundColor: $store.state.colors.headerRowColor}">
            <b-col cols="12" class="d-flex justify-content-between">
              <h4 class="text-white d-inline-block card-title">{{ title }}</h4>
            </b-col>
          </div>
        </b-row>
        <b-table
            :key="count"
            striped
            hover
            responsive
            class="position-relative users-table mt-1"
            :per-page="perPage"
            :items="kullanicilar"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
        >
          <template #cell(userName)="data">
            <strong>{{ data.item.userName }}</strong>
          </template>
          <template #cell(isim)="data">
            <p>{{ data.item.isim || ' ' }}</p>
          </template>
          <template #cell(tel)="data">
            <p>{{ data.item.tel || ' ' }}</p>
          </template>
          <template #cell(gui)="data">
            <b-badge :variant="data.item.gui === 'pc'?'success':data.item.gui === 'tablet'?'primary':'secondary'"
                     class="mt-50"
            >
              {{ data.item.gui }}
            </b-badge>
          </template>
          <template #cell(level)="data">
            <b-badge :variant="data.item.level === '1' ? 'success':'primary'">
              {{ data.item.level === '1' ? 'Yönetici' : 'Kullanıcı' }}
            </b-badge>
          </template>
          <template #cell(pasif)="data">
            <b-form-checkbox
                checked="true"
                class="custom-control-primary"
                name="check-button"
                switch
                :value="data.item.pasif === '0'"
                @change="durumChange(data.item.userID)"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon"/>
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon"/>
              </span>
            </b-form-checkbox>
          </template>
          <template #cell(eposta)="data">
            <p>{{ data.item.eposta !== 'null' ? data.item.eposta : '' }}</p>
          </template>
          <template #cell(userID)="data">
            <b-button
                v-b-modal.user-pwd-change
                variant="primary"
                class="btn-icon rounded-circle mr-50"
                size="sm"
                @click="pwdEditID = data.item.userID"
            >
              <font-awesome-icon icon="lock"/>
            </b-button>
            <b-button
                variant="warning"
                class="btn-icon rounded-circle mr-50"
                size="sm"
                @click="userAtama(data.item)"
            >
              <font-awesome-icon icon="pen"/>
            </b-button>
            <b-button
                variant="danger"
                class="btn-icon rounded-circle mr-50"
                size="sm"
                @click="userRemove(data.item.userID),formReset()"
            >
              <font-awesome-icon icon="trash"/>
            </b-button>
          </template>
        </b-table>
        <b-modal
            id="user-pwd-change"
            ok-only
            modal-class="modal-primary"
            centered
            hide-footer
            title="Şifre Değiştir"
        >
          <b-form-group
              label="Yeni Şifre"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="yeniSifre"
                autocomplete="off"
            />
          </b-form-group>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-50"
              :disabled="!yeniSifre"
              @click="pwdChange()"
          >
            Kaydet
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$bvModal.hide('user-pwd-change', yeniSifre = null,pwdEditID = null)"
          >
            Kapat
          </b-button>
        </b-modal>
      </b-card-actions>
    </b-col>
    <b-col lg="4" md="12" sm="12" cols="12">
      <b-card-actions
          ref="second-card"
          class="list-card"
          @refresh="refreshStop('second-card')"
      >
        <b-row class="mt-50 mb-5">
          <div class="card-header-row"
               :style="{backgroundColor: !editID ? $store.state.colors.headerRowColor: $store.state.colors.warningColor}"
          >
            <b-col cols="12" class="d-flex justify-content-between">
              <h4 class="text-white d-inline-block card-title">{{ titleTwo }}</h4>
            </b-col>
          </div>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Yetki Türü" label-for="basicInput">
              <v-select
                  v-model="yetkiTuru"
                  label="title"
                  :clearable="false"
                  :options="yetkiTuruOption"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Rolü" label-for="basicInput">
              <v-select
                  v-model="role"
                  label="title"
                  :clearable="false"
                  :options="roleOption"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                :label="!editID ? 'Kullanıcı Adı':'Kullanıcı Adı (değiştirilemez)'"
                label-for="basicInput"
            >
              <b-form-input
                  id="basicInput"
                  v-model="kullaniciAdi"
                  :disabled="!!editID"
                  autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Şifre"
                label-for="basicInput"
            >
              <b-form-input
                  id="basicInput"
                  v-model="sifre"
                  :disabled="!!editID"
                  autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Personel Adı Soyadı"
                label-for="basicInput"
            >
              <b-form-input
                  id="basicInput"
                  v-model="adSoyad"
                  autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Telefon"
                label-for="basicInput"
            >
              <b-form-input
                  id="basicInput"
                  v-model="telefon"
                  autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="E-Posta"
                label-for="basicInput"
            >
              <b-form-input
                  id="basicInput"
                  v-model="ePosta"
                  autocomplete="off"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="!editID ? !kullaniciAdi || !sifre : !kullaniciAdi"
            class="mr-50"
            :variant="editID ? 'relief-primary' : 'success'"
            @click="editID ? userUpdate() : userSave()"
        >
          {{ editID ? 'Güncelle' : 'Kaydet' }}
        </b-button>
        <b-button
            v-if="editID"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            @click="formReset()"
        >
          İptal
        </b-button>
      </b-card-actions>
    </b-col>
  </b-row>
</template>

<script>
import {
  VBModal,
  BTable,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  BModal,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BSpinner,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import axios from 'axios'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BTable,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BBadge,
    BSpinner,
    ToastificationContent,
    BFormRadio,
    BFormCheckbox,
    BCardText,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BCardActions,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      count: 0,
      yeniSifre: null,
      pwdEditID: null,
      editID: null,
      yetkiTuru: {
        title: 'Yönetici',
        id: 1,
      },
      yetkiTuruOption: [
        {
          title: 'Yönetici',
          id: 1,
        },
        {
          title: 'Kullanıcı',
          id: 2,
        },
      ],
      role: {
        title: 'Pc',
        id: 'pc',
      },
      roleOption: [
        {
          title: 'Pc',
          id: 'pc',
        },
        {
          title: 'Tablet',
          id: 'tablet',
        },
        {
          title: 'Mutfak',
          id: 'mutfak',
        },
      ],
      kullaniciAdi: null,
      sifre: null,
      adSoyad: null,
      telefon: null,
      ePosta: null,
      fields: [
        {
          key: 'userName',
          label: 'Kullanıcı Adı',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'isim',
          label: 'Ad Soyad',
          sortable: true,
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'tel',
          label: 'Telefon',
          sortable: true,
          thStyle: {
            width: '5%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '5%' },
        },
        {
          key: 'gui',
          label: 'Rol',
          sortable: true,
          thStyle: {
            width: '5%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '5%' },
        },
        {
          key: 'level',
          label: 'Yetki Türü',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'pasif',
          label: 'Durum',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'eposta',
          label: 'E-Posta',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'userID',
          label: 'İşlemler',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },

        },
      ],
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      toplamKayit: 1,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    titleTwo() {
      return !this.editID ? 'Kullanıcı Kaydı' : 'Kullanıcı Düzenleniyor'
    },
    title() {
      return 'Kullanıcı Listesi'
    },
    kullanicilar: () => store.getters.GET_KULLANICI_LIST,
  },
  created() {
    this.kullaniciData()
  },
  beforeDestroy() {
    store.commit({
      type: 'SET_KULLANICI_LIST',
      action: 0,
    })
  },
  methods: {
    userAtama(user) {
      this.sifre = null
      this.editID = user.userID
      this.kullaniciAdi = user.userName
      this.adSoyad = user.isim
      this.telefon = user.tel
      this.ePosta = user.eposta
      this.yetkiTuru = user.level === '1' ? {
        title: 'Yönetici',
        id: 1,
      } : user.level === '2' ? {
        title: 'Kullanıcı',
        id: 2,
      } : ''
      this.role = user.gui
    },
    pwdChange() {
      const fd = this.postSchema()
      fd.append('methodName', 'sifreDegistir')
      fd.append('userID', this.pwdEditID)
      fd.append('pwd', this.yeniSifre)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.$bvModal.hide('user-pwd-change')
              this.pwdEditID = null
              this.yeniSifre = null
              this.kullaniciData()
            }
          })
    },
    durumChange(userID) {
      const fd = this.postSchema()
      fd.append('methodName', 'userStatus')
      fd.append('userID', userID)
      axios.post('', fd)
          .then(response => this.dataRefresh(response))
    },
    userUpdate() {
      const fd = this.postSchema()
      fd.append('methodName', 'kullaniciDuzenle')
      fd.append('userID', this.editID)
      fd.append('updateData[level]', this.yetkiTuru.id)
      fd.append('updateData[gui]', this.role.id || this.role)
      fd.append('updateData[isim]', this.adSoyad || ' ')
      fd.append('updateData[tel]', this.telefon || ' ')
      fd.append('updateData[ePosta]', this.ePosta || ' ')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.formReset()
              this.dataRefresh(response)
            }
          })
    },
    userSave() {
      const fd = this.postSchema()
      fd.append('methodName', 'kullaniciKayit')
      fd.append('saveData[level]', this.yetkiTuru.id)
      fd.append('saveData[gui]', this.role.id)
      fd.append('saveData[userName]', this.kullaniciAdi || ' ')
      fd.append('saveData[pwd]', this.sifre || ' ')
      fd.append('saveData[isim]', this.adSoyad || ' ')
      fd.append('saveData[tel]', this.telefon || ' ')
      fd.append('saveData[ePosta]', this.ePosta || ' ')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.formReset()
              this.dataRefresh(response)
            }
          })
    },
    formReset() {
      this.yetkiTuru = {
        title: 'Yönetici',
        id: 1,
      }
      this.editID = null
      this.role = {
        title: 'Pc',
        id: 'pc',
      }
      this.kullaniciAdi = null
      this.sifre = null
      this.adSoyad = null
      this.telefon = null
      this.ePosta = null
    },
    userRemove(id) {
      const fd = this.postSchema()
      fd.append('methodName', 'kullaniciSil')
      fd.append('userID', id)
      axios.post('', fd)
          .then(response => this.dataRefresh(response))
    },
    kullaniciData() {
      const fd = this.postSchema()
      fd.append('methodName', 'kullaniciListesi')
      axios.post('', fd)
          .then(response => this.dataRefresh(response))
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'restoran')
      return fd
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
    dataRefresh(response) {
      if (response.result.status === 200) {
        store.commit({
          type: 'SET_KULLANICI_LIST',
          action: 1,
          list: response.kullaniciList,
        })
        this.count++
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.users-table .table td {
  text-align: center;
  padding: 0.22rem 1rem !important;
}

.users-table .table th {
  text-align: center;
}

</style>
